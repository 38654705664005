/** @typedef {import('@spider:src/enums/OrderStatuses').OrderStatusesType} OrderStatus*/
/** @typedef {import('@spider:src/enums/pointCommandIds').PointCommandIdsType} PointCommandIdsType*/
/** @typedef {{
 *   uuid: PointCommandIdsType,
 *   name: string
 * }} Reward */
/** @typedef {{
 *   uuid: string,
 *   reward: Reward
 * }} RewardAllocation */
/** @typedef {{
 *   quantity: number,
 *   points: number,
 *   monetary_value: number,
 *   reward_allocation: RewardAllocation
 * }} OrderItem */
/** @typedef {{
 *   code: string,
 *   name: string
 * }} ContractContext */
/** @typedef {{
 *   uuid: string,
 *   email: string,
 *   first_identifier: string,
 *   firstname: string,
 *   lastname: string,
 *   role: 'ADMINISTRATOR' | 'COLLABORATOR',
 *   type: 'INDIVIDUAL' | 'LEGAL_ENTITY',
 *   status: 'ACTIVE',
 *   created_at: number,
 *   updated_at: number,
 *   has_user_account: boolean,
 *   _context: { contract: ContractContext },
 *   owner_type: string
 * }} OrderOwner */
/** @typedef {{
 *   uuid: string,
 *   points: number,
 *   monetary_value: number,
 *   number: string,
 *   items: [OrderItem],
 *   creation_date: number,
 *   owner: OrderOwner,
 *   status: OrderStatus
 * }} Order */

import { mockedGetDetails, mockedGetList } from '../fetching';

/** @type {Order[]} */
export const ordersList = [
  {
    uuid: '4ff1a735-91e4-4ffd-b2fa-a94644b80914',
    points: 93,
    monetary_value: 81,
    number: 'ORO202410220000000035',
    items: [
      {
        quantity: 1,
        points: 93,
        monetary_value: 81,
        reward_allocation: {
          uuid: 'fd6a29ed-3686-49ec-a8c6-5bd271a26031',
          reward: {
            uuid: '7275fd9c-a82b-493f-8358-e4e3c0d0bcca',
            name: 'Chèque Kadéos',
          },
        },
      },
    ],
    creation_date: 1709251200,
    owner: {
      uuid: '2f06255b-1d1f-41e5-a8b7-d1741f3949d2',
      email: 'damien.corticchiato@objow.com',
      first_identifier: 'M0000000009',
      firstname: 'Damien',
      lastname: 'Corticchiato',
      role: 'COLLABORATOR',
      type: 'LEGAL_ENTITY',
      status: 'ACTIVE',
      created_at: 1730988470,
      updated_at: 1730988470,
      has_user_account: true,
      _context: {
        contract: {
          code: 'b9a400cd-a8d0-43e2-8c19-08a4002c0ab3',
          name: 'Campagne de Boost des Performances Commerciales',
        },
      },
      owner_type: 'HIERARCHY_NODE_USER',
    },
    status: 'COMPLETED',
  },
  {
    uuid: 'f093f725-2ffc-4bd9-a432-1180b5de2ae0',
    points: 68,
    monetary_value: 56,
    number: 'ORO202410220000000098',
    items: [
      {
        quantity: 1,
        points: 68,
        monetary_value: 56,
        reward_allocation: {
          uuid: '54a14d01-4918-442f-9685-e9b779c433c4',
          reward: {
            uuid: 'bb31a54a-3e25-44da-95f5-8a6e005122a7',
            name: 'Carte Kadéos Universel',
          },
        },
      },
    ],
    creation_date: 1709251200,
    owner: {
      uuid: 'aeee9107-d165-4c6e-8c5d-baae52293419',
      email: 'gilles.fantone@objow.com',
      first_identifier: 'M0000000025',
      firstname: 'Gilles',
      lastname: 'Fantone',
      role: 'ADMINISTRATOR',
      type: 'LEGAL_ENTITY',
      status: 'ACTIVE',
      created_at: 1730988470,
      updated_at: 1730988470,
      has_user_account: true,
      _context: {
        contract: {
          code: 'b9a400cd-a8d0-43e2-8c19-08a4002c0ab3',
          name: 'Campagne de Boost des Performances Commerciales',
        },
      },
      owner_type: 'HIERARCHY_NODE_USER',
    },
    status: 'COMPLETED',
  },
  {
    uuid: '03813415-4fa6-4be8-b86c-7506a60bf025',
    points: 94,
    monetary_value: 82,
    number: 'ORO202410220000000038',
    items: [
      {
        quantity: 1,
        points: 94,
        monetary_value: 82,
        reward_allocation: {
          uuid: '54a14d01-4918-442f-9685-e9b779c433c4',
          reward: {
            uuid: 'bb31a54a-3e25-44da-95f5-8a6e005122a7',
            name: 'Carte Kadéos Universel',
          },
        },
      },
    ],
    creation_date: 1709251200,
    owner: {
      uuid: '8e849a0e-e3d1-4f5e-9bc8-0b9780c32ae4',
      email: 'damien.corticchiato@objow.com',
      first_identifier: 'M0000000010',
      firstname: 'Damien',
      lastname: 'Corticchiato',
      role: 'COLLABORATOR',
      type: 'INDIVIDUAL',
      status: 'ACTIVE',
      created_at: 1730988470,
      updated_at: 1730988470,
      has_user_account: true,
      _context: {
        contract: {
          code: 'b9a400cd-a8d0-43e2-8c19-08a4002c0ab3',
          name: 'Campagne de Boost des Performances Commerciales',
        },
      },
      owner_type: 'HIERARCHY_NODE_USER',
    },
    status: 'COMPLETED',
  },
  {
    uuid: '81418cc4-cdf3-4eb4-8c5c-00a1dacc37f0',
    points: 67,
    monetary_value: 55,
    number: 'ORO202410220000000104',
    items: [
      {
        quantity: 1,
        points: 67,
        monetary_value: 55,
        reward_allocation: {
          uuid: '529a8041-15b5-499e-9d2a-b50068b85e60',
          reward: {
            uuid: 'b2ede966-d014-44bc-9926-2489e2e64410',
            name: 'Kadéos Connect',
          },
        },
      },
    ],
    creation_date: 1709251200,
    owner: {
      uuid: 'ec9e7777-c278-4bb6-ad31-d50fd5ef121f',
      email: 'gilles.fantone@objow.com',
      first_identifier: 'M0000000026',
      firstname: 'Gilles',
      lastname: 'Fantone',
      role: 'ADMINISTRATOR',
      type: 'INDIVIDUAL',
      status: 'ACTIVE',
      created_at: 1730988470,
      updated_at: 1730988470,
      has_user_account: true,
      _context: {
        contract: {
          code: 'b9a400cd-a8d0-43e2-8c19-08a4002c0ab3',
          name: 'Campagne de Boost des Performances Commerciales',
        },
      },
      owner_type: 'HIERARCHY_NODE_USER',
    },
    status: 'COMPLETED',
  },
  {
    uuid: 'a0de447e-baac-4c95-aaf2-0c11a1157b7a',
    points: 80,
    monetary_value: 68,
    number: 'ORO202410220000000070',
    items: [
      {
        quantity: 1,
        points: 80,
        monetary_value: 68,
        reward_allocation: {
          uuid: '54a14d01-4918-442f-9685-e9b779c433c4',
          reward: {
            uuid: 'bb31a54a-3e25-44da-95f5-8a6e005122a7',
            name: 'Carte Kadéos Universel',
          },
        },
      },
    ],
    creation_date: 1709251200,
    owner: {
      uuid: 'd0d69a5d-fd5b-4d2c-8140-9d84908d19c7',
      email: 'gaetan@squareglasses.com',
      first_identifier: 'M0000000018',
      firstname: 'Gaetan',
      lastname: 'Squareglasses',
      role: 'COLLABORATOR',
      type: 'INDIVIDUAL',
      status: 'ACTIVE',
      created_at: 1730988470,
      updated_at: 1730988470,
      has_user_account: true,
      _context: {
        contract: {
          code: 'b9a400cd-a8d0-43e2-8c19-08a4002c0ab3',
          name: 'Campagne de Boost des Performances Commerciales',
        },
      },
      owner_type: 'HIERARCHY_NODE_USER',
    },
    status: 'COMPLETED',
  },
  {
    uuid: '8b19eb46-8240-4c1f-8488-d393519412ea',
    points: 130,
    monetary_value: 106,
    number: 'ORO202410220000000101',
    items: [
      {
        quantity: 2,
        points: 65,
        monetary_value: 53,
        reward_allocation: {
          uuid: '4bde9eb9-4502-4397-9585-8be7ae41d4ef',
          reward: {
            uuid: 'eb9ee20e-a303-4f6c-99b7-3ccc8097ca63',
            name: 'Carte Kadéos',
          },
        },
      },
    ],
    creation_date: 1709251200,
    owner: {
      uuid: 'ec9e7777-c278-4bb6-ad31-d50fd5ef121f',
      email: 'gilles.fantone@objow.com',
      first_identifier: 'M0000000026',
      firstname: 'Gilles',
      lastname: 'Fantone',
      role: 'ADMINISTRATOR',
      type: 'INDIVIDUAL',
      status: 'ACTIVE',
      created_at: 1730988470,
      updated_at: 1730988470,
      has_user_account: true,
      _context: {
        contract: {
          code: 'b9a400cd-a8d0-43e2-8c19-08a4002c0ab3',
          name: 'Campagne de Boost des Performances Commerciales',
        },
      },
      owner_type: 'HIERARCHY_NODE_USER',
    },
    status: 'COMPLETED',
  },
  {
    uuid: '0de361bc-cfd7-4331-b253-a8473bb8fe54',
    points: 57,
    monetary_value: 45,
    number: 'ORO202410220000000163',
    items: [
      {
        quantity: 1,
        points: 57,
        monetary_value: 45,
        reward_allocation: {
          uuid: 'fd6a29ed-3686-49ec-a8c6-5bd271a26031',
          reward: {
            uuid: '7275fd9c-a82b-493f-8358-e4e3c0d0bcca',
            name: 'Chèque Kadéos',
          },
        },
      },
    ],
    creation_date: 1709251200,
    owner: {
      uuid: 'e2b2d169-b73f-4094-8339-9ec6a2be36f3',
      email: 'marielou@squareglasses.com',
      first_identifier: 'M0000000041',
      firstname: 'Marie-Lou',
      lastname: 'Perreau',
      role: 'ADMINISTRATOR',
      type: 'LEGAL_ENTITY',
      status: 'ACTIVE',
      created_at: 1730988470,
      updated_at: 1730988470,
      has_user_account: true,
      _context: {
        contract: {
          code: 'b9a400cd-a8d0-43e2-8c19-08a4002c0ab3',
          name: 'Campagne de Boost des Performances Commerciales',
        },
      },
      owner_type: 'HIERARCHY_NODE_USER',
    },
    status: 'COMPLETED',
  },
  {
    uuid: 'a39522a2-d82b-4bec-80ad-a0e3f66b045a',
    points: 180,
    monetary_value: 156,
    number: 'ORO202410220000000097',
    items: [
      {
        quantity: 2,
        points: 90,
        monetary_value: 78,
        reward_allocation: {
          uuid: '4bde9eb9-4502-4397-9585-8be7ae41d4ef',
          reward: {
            uuid: 'eb9ee20e-a303-4f6c-99b7-3ccc8097ca63',
            name: 'Carte Kadéos',
          },
        },
      },
    ],
    creation_date: 1709251200,
    owner: {
      uuid: 'aeee9107-d165-4c6e-8c5d-baae52293419',
      email: 'gilles.fantone@objow.com',
      first_identifier: 'M0000000025',
      firstname: 'Gilles',
      lastname: 'Fantone',
      role: 'ADMINISTRATOR',
      type: 'LEGAL_ENTITY',
      status: 'ACTIVE',
      created_at: 1730988470,
      updated_at: 1730988470,
      has_user_account: true,
      _context: {
        contract: {
          code: 'b9a400cd-a8d0-43e2-8c19-08a4002c0ab3',
          name: 'Campagne de Boost des Performances Commerciales',
        },
      },
      owner_type: 'HIERARCHY_NODE_USER',
    },
    status: 'COMPLETED',
  },
  {
    uuid: '6eb9b2db-77d3-424d-91e8-3ff8eb846d1a',
    points: 81,
    monetary_value: 69,
    number: 'ORO202410220000000232',
    items: [
      {
        quantity: 1,
        points: 81,
        monetary_value: 69,
        reward_allocation: {
          uuid: '529a8041-15b5-499e-9d2a-b50068b85e60',
          reward: {
            uuid: 'b2ede966-d014-44bc-9926-2489e2e64410',
            name: 'Kadéos Connect',
          },
        },
      },
    ],
    creation_date: 1709251200,
    owner: {
      uuid: '87659dd0-1a0f-4b75-9e3b-c83d53f5022a',
      email: 'olga.kuzkina@objow.com',
      first_identifier: 'M0000000058',
      firstname: 'Olga',
      lastname: 'Kuzkina',
      role: 'ADMINISTRATOR',
      type: 'INDIVIDUAL',
      status: 'ACTIVE',
      created_at: 1730988470,
      updated_at: 1730988470,
      has_user_account: true,
      _context: {
        contract: {
          code: 'b9a400cd-a8d0-43e2-8c19-08a4002c0ab3',
          name: 'Campagne de Boost des Performances Commerciales',
        },
      },
      owner_type: 'HIERARCHY_NODE_USER',
    },
    status: 'COMPLETED',
  },
  {
    uuid: 'e728817f-b50e-4b93-b995-0b2d4356ad21',
    points: 138,
    monetary_value: 114,
    number: 'ORO202410220000000037',
    items: [
      {
        quantity: 2,
        points: 69,
        monetary_value: 57,
        reward_allocation: {
          uuid: '4bde9eb9-4502-4397-9585-8be7ae41d4ef',
          reward: {
            uuid: 'eb9ee20e-a303-4f6c-99b7-3ccc8097ca63',
            name: 'Carte Kadéos',
          },
        },
      },
    ],
    creation_date: 1709251200,
    owner: {
      uuid: '8e849a0e-e3d1-4f5e-9bc8-0b9780c32ae4',
      email: 'damien.corticchiato@objow.com',
      first_identifier: 'M0000000010',
      firstname: 'Damien',
      lastname: 'Corticchiato',
      role: 'COLLABORATOR',
      type: 'INDIVIDUAL',
      status: 'ACTIVE',
      created_at: 1730988470,
      updated_at: 1730988470,
      has_user_account: true,
      _context: {
        contract: {
          code: 'b9a400cd-a8d0-43e2-8c19-08a4002c0ab3',
          name: 'Campagne de Boost des Performances Commerciales',
        },
      },
      owner_type: 'HIERARCHY_NODE_USER',
    },
    status: 'COMPLETED',
  },
];

export const mockedGetOrderList = params => mockedGetList(params, ordersList);

export const mockedGetOrderDetails = ({ id }) =>
  mockedGetDetails(ordersList, order => order.uuid === id);
