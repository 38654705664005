import { Box, CircularProgress } from '@material-ui/core';
import { getIntlEnumLabel } from '@src/Spider/enums/utils';
import React from 'react';
import { useIntl } from 'react-intl';
import { Info } from './components';
import { InfoCircle, UserOctagon } from 'iconsax-react';
import { formatDate } from '@src/helpers/DateHelper';
/** @typedef {import("@async-calls/hierarchyNodeUsers").UserBodyDetailed} UserBodyDetailed */

/** @param {{user: UserBodyDetailed,isLoading:boolean,  className: string}} props */
export const DetailsTab = ({ user, isLoading }) => {
  const intl = useIntl();

  if (isLoading) return <circularprogress></circularprogress>;

  const generalInfos = [
    { label: 'matricule', value: user.first_identifier },
    { label: 'firstname', value: user.firstname },
    { label: 'lastname', value: user.lastname },
    {
      label: 'type',
      value: intl.formatMessage({
        id: getIntlEnumLabel('participant_type', user.type),
      }),
    },
    { label: 'contactEmail', value: user.email },
    { label: 'creationDate', value: formatDate(user.created_at) },
    { label: 'lastUpdated', value: formatDate(user.updated_at) },
  ];

  const managerInfos = [
    { label: 'matricule', value: user.manager?.first_identifier },
    { label: 'firstname', value: user.manager?.firstname },
    { label: 'lastname', value: user.manager?.lastname },
  ];

  return (
    <box sx="{{" display:="" 'flex',="" flexDirection:="" 'column',="" gap:="" '1rem'="" }}="">
      <info.card title="Informations générales" icon="{<InfoCircle"></info.card>}>
        {generalInfos.map(({ value, label }, i) => (
          <info.item key="{i}" value="{value}" label="{intl.formatMessage({" id:="" `spider.participants.details.generalInfos.${label}`,="" })}=""></info.item>
        ))}
      
      {Boolean(user.manager) && (
        <info.card title="Responsable" icon="{<UserOctagon"></info.card>}>
          {managerInfos.map(({ value, label }, i) => (
            <info.item key="{i}" value="{value}" label="{intl.formatMessage({" id:="" `spider.participants.details.managerInfos.${label}`,="" })}=""></info.item>
          ))}
        
      )}
    </box>
  );
};
