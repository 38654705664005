import React from 'react';
import { Route } from 'react-router-dom';

import { GuestLayout } from './index';

const GuestRoutes = ({ component: Component, ...rest }) => {
  return (
    <route {...rest}="" render="{props" ==""> <guestlayout component="{Component}" {...props}=""></guestlayout>}
    />
  );
};

export default GuestRoutes;
</route>