import React, { useState } from 'react';
import { PointCommandIds } from '@spider:src/enums';
import { MainRewardConvertCard } from '../MainRewardConvertCard';
import { RewardConvertCard } from '../RewardConvertCard';
import useCommandReward from '@spider:src/hooks/CommandReward/UseCommandReward';

const RewardCard = ({ reward, ...props }) => {
  const [isOpenRewardHelperAbout, setIsOpenRewardHelperAbout] = useState(false);
  const { ComponentDecoration, cardDetails, HelperAbout } =
    useCommandReward(reward);

  return (
    <react.fragment>
      {reward.uuid === PointCommandIds.KSC && (
        <mainrewardconvertcard reward="{reward}" setOpenHelperAbout="{setIsOpenRewardHelperAbout}" {...props}=""></mainrewardconvertcard>
      )}
      {reward.uuid !== PointCommandIds.KSC && (
        <rewardconvertcard reward="{reward}" cardDetails="{cardDetails}" ComponentDecoration="{ComponentDecoration}" setOpenHelperAbout="{setIsOpenRewardHelperAbout}" {...props}=""></rewardconvertcard>
      )}

      {/*<rewardhelperaboutdialog title="{'test'}" setOpen="{setIsOpenRewardHelperAbout}" open="{isOpenRewardHelperAbout}*/}" {="" *="" disableConvert="{disableConvert}" onConvert="{props.openConvertPointView}" AboutComponent="{HelperAbout}"></rewardhelperaboutdialog>*/}
    </react.fragment>
  );
};

export default RewardCard;
