import React, { useState } from 'react';
import { Box, Typography, withStyles, Tooltip } from '@material-ui/core';
import { gradients, neutralColors, systemColors } from '@src/Spider';
import { Button, HierarchyNodeUserStatus } from '@src/Spider/components';
import { useIntl } from 'react-intl';
import { ChangeStatusDialog } from './ChangeStatusDialog';
import { useDisclosure } from '@src/Spider/hooks/useDisclosure';
import { AccountStatus } from '@src/Spider/components/AccountStatus';
import { Link } from 'react-router-dom';
import { InfoCircle } from 'iconsax-react';
import { useUpdateHierarchyNodeUserStatusMutation } from '@src/data/api/endpoints/hierarchyNodeUsers';
import { toast } from 'react-toastify';

const styles = {
  root: {
    backgroundColor: neutralColors.neutralBg,
    borderRadius: '0.5rem',
    padding: '1rem',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  image: {
    width: '2.25rem',
    height: '2.25rem',
  },
  matricule: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  btn: {
    padding: '0.1rem 0.75rem',
    borderRadius: '0.5rem',
  },
  resendEmailBtn: {
    color: systemColors.infoRegular,
  },
  points: {
    fontSize: '2rem',
    fontWeight: 'bold',
    background: gradients.gradientLightRedRose,
    '-webkit-background-clip': 'text',
    'background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    color: 'black', // Fallback for browsers that don't support background-clip: text
  },
  sub: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.25rem',
  },
};

/**
 * @param {{user: import("@async-calls/hierarchyNodeUsers").UserBodyDetailed, className: string}} props
 */
const Component = ({ user, className, classes }) => {
  const { formatMessage } = useIntl();
  const { open, onClose, onOpen } = useDisclosure();
  const isUserActive = user?.status === 'ACTIVE';
  const [sendActivationEmail] = useUpdateHierarchyNodeUserStatusMutation();
  const [canSendActivationEmail, setCanSendActivationEmail] = useState(true);

  const handleSendActivationEmail = async () => {
    try {
      await sendActivationEmail({
        user_id: user?.uuid,
      }).unwrap();
      toast.success(
        formatMessage({
          id: 'spider.hierarchy_node_user_status.email.resend.success',
        }),
      );
      setCanSendActivationEmail(false);
    } catch (error) {
      console.error('Failed to update status:', error);
      toast.error(
        formatMessage({
          id: 'spider.hierarchy_node_user_status.email.resend.error',
        }),
      );
    }
  };

  return (
    <>
      <box className="{`${classes.root}" ${className}`}="">
        <box sx="{{" display:="" 'flex',="" gap:="" '0.5rem',="" alignItems:="" 'center'="" }}="">
          <hierarchynodeuserstatus status="{user.status}"></hierarchynodeuserstatus>
          <button variant="outlinedPrimary" onClick="{onOpen}" className="{classes.btn}">
            {formatMessage({
              id: isUserActive
                ? 'spider.common.unsubscribe'
                : 'spider.common.subscribe',
            })}
          </button>
        </box>
        <accountstatus hasUserAccount="{user.has_user_account}"></accountstatus>
        {!isUserActive && (
          <typography>
            <typography component="span">
              {formatMessage({
                id: 'spider.hierarchy_node_user_status.email.activationProblem',
              })}
            </typography>
            <box sx="{{" display:="" 'inline-flex',="" gap:="" '0.5rem',="" alignItems:="" 'center',="" }}="" component="span">
              <button variant="text" onClick="{handleSendActivationEmail}" disabled="{!canSendActivationEmail}" className="{classes.resendEmailBtn}">
                {formatMessage({
                  id: 'spider.hierarchy_node_user_status.email.resendEmail',
                })}
              </button>
              <tooltip title="{formatMessage({" id:="" 'spider.hierarchy_node_user_status.email.tooltip',="" })}="" placement="top" sx="{{" marginLeft:="" '0.3rem'="" }}="">
                <infocircle fill="{systemColors.infoRegular}"></infocircle>
              </tooltip>
            </box>
          </typography>
        )}
      </box>
      <changestatusdialog open="{open}" handleClose="{onClose}" user="{user}"></changestatusdialog>
    </>
  );
};

const StatusCard = withStyles(styles)(Component);

export { StatusCard };
