import React, { useMemo, useState } from 'react';
import { withStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import { Box, Tab, Tabs, Typography } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import { Button } from '@spider:src/components';
import ImportDialog from '@spider:src/components/ImportDialog/ImportDialog';
import { ImportLogsHistory } from '@spider:src/components/importLogsHistory';
import { ImportLogsTypes } from '@spider:src/enums/importLogsTypes';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import { TitleContainer } from '@spider:src/components/TitleContainer';
import { ParticipantsList } from '@spider:src/components/ParticipantsList';
import { PointsAdminUrl } from '@src/Spider/enums/pointsAdminUrl';
import { useRouteMatch } from 'react-router';
import { ParticipantsAdminUrl } from '@src/Spider/enums/participantsAdminUrl';

const styles = {
  pointsBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    paddingRight: '0.5rem',
  },
  pointsHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '1rem',
  },
};

const Participants = ({ ...props }) => {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const { contract } = useParams();

  const tabPaths = Object.values(ParticipantsAdminUrl);
  const tabs = useMemo(
    () =>
      tabPaths.map((el, i) => ({
        label: intl.formatMessage({
          id: `spider.participants.tabs.${el}`,
        }),
        index: i,
        url: el,
      })),
    [intl, tabPaths],
  );

  const pathSegments = location.pathname.split('/').filter(Boolean);
  const currentTab = pathSegments.pop();
  const currentTabIndex = tabPaths.indexOf(currentTab);
  const value = String(currentTabIndex !== -1 ? currentTabIndex : 0);

  const handleChange = (_, newValue) => {
    history.push(`${match.url}/${tabPaths[Number(newValue)]}`);
  };

  const [openImport, setOpenImport] = useState(false);

  const importPoints = () => {
    setOpenImport(true);
  };

  const redirect = options => {
    if (
      options.type === ImportLogsTypes.POINTS &&
      history.location.pathname.includes('users')
    ) {
      history.push(`/nodes/${contract}/points/${PointsAdminUrl.HISTORY}`);
    }
  };

  if (!currentTab || currentTab === 'users') {
    return <redirect to="{`${match.url}/${ParticipantsAdminUrl.LIST}`}"></redirect>;
  }

  return (
    <div className="{props.classes.pointsBody}">
      <titlecontainer>
        <typography variant="{'h1'}" component="{'h1'}" className="{'underline-left'}">
          {intl.formatMessage({ id: 'spider.participants.title' })}
        </typography>
        <button color="{'primary'}" variant="{'outlined'}" onClick="{importPoints}">
          {intl.formatMessage({ id: 'spider.participants.import' })}
        </button>
      </titlecontainer>

      <box style="{{" backgroundColor:="" 'white',="" borderRadius:="" '1.5rem',="" }}="">
        <tabcontext value="{value}">
          <box sx="{{" borderBottom:="" 1,="" borderColor:="" 'divider'="" }}="">
            <tabs onChange="{handleChange}" value="{value}">
              {tabs.map((tab, index) => (
                <tab key="{index}" label="{tab.label}" value="{tab.index.toString()}" disableFocusRipple=""></tab>
              ))}
            </tabs>
          </box>
          <switch>
            <route path="{`${match.path}/${ParticipantsAdminUrl.LIST}`}" render="{()" ==""> (
                <tabpanel value="{tabs[0].index.toString()}">
                  <participantslist></participantslist>
                </tabpanel>
              )}
            />
            <route path="{`${match.path}/${ParticipantsAdminUrl.HISTORY}`}" render="{()" ==""> (
                <tabpanel value="{tabs[1].index.toString()}">
                  <importlogshistory fixedCacheKey="{'participants'}" importLogType="{ImportLogsTypes.USERS}" emptyLabelParams="{intl.formatMessage({" id:="" 'spider.importLog.dataTable.no_data_participants',="" })}=""></importlogshistory>
                </tabpanel>
              )}
            />
            <route path="*" render="{()" ==""> (
                <redirect to="{`${match.url}/${ParticipantsAdminUrl.LIST}`}"></redirect>
              )}
            />
          </route></route></route></switch>
        </tabcontext>
      </box>
      <importdialog open="{openImport}" setOpen="{setOpenImport}" onFileCreated="{redirect}" defaultTab="{'2'}" fixedCacheKey="{'participant'}"></importdialog>
    </div>
  );
};

export default withStyles(styles)(Participants);
