import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { withStyles } from '@mui/styles';
import cku from '@spider:src/assets/img/cku.png';
import cks from '@spider:src/assets/img/cks.png';
import ks from '@spider:src/assets/img/ks.png';
import ksc from '@spider:src/assets/img/ksc.png';
import { PointCommandIds } from '@src/Spider/enums';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
    maxWidth: '12rem',
    minWidth: '12rem',
  },
};

/**
 * @typedef {import('@spider:src/enums/pointCommandIds').PointCommandIdsType} PointCommandIdsType
 * @param {{
 *   product?: PointCommandIdsType,
 *   classes: {
 *     root: string
 *   }
 * }} props
 */
const Component = ({ product, classes }) => {
  const { image, label } = selectProduct(product);
  return (
    <box className="{classes.root}">
      <img src="{image}" alt="{label}">
      <typography>{label}</typography>
    </box>
  );
};

const ProductType = withStyles(styles)(Component);
export { ProductType };

/**
 * @param {PointCommandIdsType | undefined} productName
 * @returns {{image: string, label: string}}
 */
const selectProduct = productName => {
  switch (productName) {
    case PointCommandIds.CKS: {
      return {
        image: cks,
        label: 'Carte Kadéos',
      };
    }
    case PointCommandIds.CKU: {
      return {
        image: cku,
        label: 'Carte Kadéos Universel',
      };
    }
    case PointCommandIds.KS: {
      return {
        image: ks,
        label: 'Chèque Kadéos',
      };
    }
    case PointCommandIds.KSC: {
      return {
        image: ksc,
        label: 'Kadéos Connect',
      };
    }
    default: {
      return {
        image: cks,
        label: productName || 'Unknown Product',
      };
    }
  }
};
