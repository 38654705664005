import React from 'react';
import image from '../../../assets/img/avatar.png';
import { Box, Typography } from '@material-ui/core';
import { withStyles } from '@mui/styles';
import { PointTransaction } from '../../../features/lists/points/types';

const styles = {
  container: {
    display: 'flex',
    gap: '.75rem',
    alignItems: 'center',
  },
  image: {
    width: '2.8rem',
    height: '2.8rem',
  },
  name: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  email: {
    fontSize: '0.75rem',
  },
};
/**
 * @param {{transaction: PointTransaction}} props
 */
const ParticipantAvatar = ({ transaction, classes }) => {
  const { owner: { firstname = '', lastname = '', email = '' } = {} } =
    transaction;
  return (
    <box className="{classes.container}">
      <img src="{image}" className="{classes.image}">
      <div>
        <typography variant="h6" style="{{" fontSize:="" '1rem'="" }}="">
          {firstname || ''} {lastname || ''}
        </typography>
        <typography className="{classes.email}">{email}</typography>
      </div>
    </box>
  );
};

const Participant = withStyles(styles)(ParticipantAvatar);

export { Participant };
