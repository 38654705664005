import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import { Button } from '@spider:src/components';
import { Trash, Edit } from 'iconsax-react';
import { RightDialogFileInput } from '@spider:src/components/RightDialogFileInput';
import UseSystemFile from '@spider:src/hooks/UseSystemFile';
import { neutralColors } from '@src/Spider';

const styles = {
  editableImage: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'clip',
  },
  editableImageActions: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: '0.4rem',
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
  },
};

const EditableImage = ({
  imageData,
  code,
  teamGroupId,
  title,
  defaultColor = '',
  altImage,
  imageWidth,
  imageHeight,
  setImageData,
  fileUpdatedCallback,
  fileDeletedCallback,
  ...props
}) => {
  const [isOpenScreenRightDialog, setIsOpenScreenRightDialog] = useState(false);

  const onFileUpdated = imageUpdated => {
    setIsOpenScreenRightDialog(false);
    setImageData(imageUpdated);
    fileUpdatedCallback(imageUpdated);
  };

  const onFileDeleted = () => {
    setImageData(null);
    fileDeletedCallback();
  };

  const { updateSystemFile, deleteSystemFile } = UseSystemFile({
    onFileUpdated,
    onFileDeleted,
  });

  const deleteImage = () => {
    if (!imageData) return;
    deleteSystemFile(imageData);
  };

  const updateImage = files => {
    if (!code) return;
    updateSystemFile(files[0], code, { teamGroup: teamGroupId });
  };

  const openEditImage = () => {
    setIsOpenScreenRightDialog(true);
  };

  return (
    <div className="{props.classes.editableImage}" style="{{" background:="" defaultColor="" }}="">
      {imageData && (
        <img alt="{altImage}" src="{imageData.src}" width="{imageWidth}" height="{imageHeight}" style="{{" objectFit:="" 'cover'="" }}="">
      )}
      <div className="{props.classes.editableImageActions}">
        {imageData && (
          <button variant="{'contained'}" color="{'primary'}" size="{'small'}" className="{'size-tiny" icon'}="" style="{{" backgroundColor:="" neutralColors.neutralWhite,="" color:="" neutralColors.neutral900,="" }}="" onClick="{deleteImage}">
            <trash></trash>
          </button>
        )}
        <button variant="{'contained'}" color="{'primary'}" size="{'small'}" className="{'size-tiny" icon'}="" style="{{" backgroundColor:="" neutralColors.neutralWhite,="" color:="" neutralColors.neutral900,="" }}="" onClick="{openEditImage}">
          <edit></edit>
        </button>
      </div>

      <rightdialogfileinput open="{isOpenScreenRightDialog}" title="{title}" setOpen="{value" ==""> setIsOpenScreenRightDialog(value)}
        onValid={updateImage}
      />
    </rightdialogfileinput></div>
  );
};

export default withStyles(styles)(EditableImage);
