import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { Button } from '@src/Spider/components';
import { RightDialogFileInput } from '@spider:components/RightDialogFileInput';
import useSystemFileRefactor from '@src/Spider/hooks/useSystemFileRefactor';
import {
  useDeleteRewardAllocationImageMutation,
  useUpdateRewardAllocationImageMutation,
} from '@async-calls/rewards';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1rem',
  },
  enableConfigurationImage: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
  },
};

const ConfigurationImage = ({
  rewardAllocationId,
  rewardAllocationImage,
  code,
  rewardLabel,
  ...props
}) => {
  const intl = useIntl();

  const [customizeCardVisual, setCustomizeCardVisual] = useState(
    rewardAllocationImage !== null,
  );
  const [isOpenScreenRightDialog, setIsOpenScreenRightDialog] = useState(false);

  const uploadConfigurationImage = () => {
    setIsOpenScreenRightDialog(true);
  };

  const onFileUpdated = () => {
    setIsOpenScreenRightDialog(false);
    // re-fetch reward once images can be retrieved through them
  };

  const { updateSystemFile } = useSystemFileRefactor({
    onFileUpdated,
    onFileDeleted: () => {},
    fixedCacheKey: `system-file${rewardAllocationId}`,
    updateSystemFileMutation: useUpdateRewardAllocationImageMutation,
    deleteSystemFileMutation: useDeleteRewardAllocationImageMutation,
  });

  const updateFile = async files => {
    const file = new FormData();
    file.append('code', code);
    file.append('src', files[0]);
    await updateSystemFile(file, { id: rewardAllocationId, code });
  };

  return (
    <div className="{props.classes.root}">
      <formcontrol required="" style="{{" display:="" 'flex',="" justifyContent:="" 'left',="" flexDirection:="" 'column',="" }}="">
        <formcontrollabel control="{" <Checkbox="" checked="{customizeCardVisual}" className="{'root'}" disableRipple="" checkedIcon="{<span" checkedIcon'}=""></formcontrollabel>}
              icon={<span className="{'icon'}"></span>}
              inputProps={{ 'aria-label': 'decorative checkbox' }}
              onChange={e => setCustomizeCardVisual(e.target.checked)}
            />
          }
          label={intl.formatMessage({
            id: 'spider.hierarchy_node.platform_customization.parameters.rewards.customizeCardVisualLabel',
          })}
          style={{ margin: 0 }}
        />
      </formcontrol>

      {customizeCardVisual && (
        <div className="{props.classes.root}">
          {rewardAllocationImage && (
            <typography variant="{'body1'}">
              {rewardAllocationImage.filename}
            </typography>
          )}

          <button color="{'secondary'}" variant="{'outlined'}" size="{'small'}" onClick="{uploadConfigurationImage}">
            {rewardAllocationImage === null
              ? intl.formatMessage({
                  id: 'spider.hierarchy_node.platform_customization.parameters.rewards.importCardVisualActionButton',
                })
              : intl.formatMessage({
                  id: 'spider.hierarchy_node.platform_customization.parameters.rewards.replaceCardVisualActionButton',
                })}
          </button>
        </div>
      )}

      <rightdialogfileinput open="{isOpenScreenRightDialog}" title="{intl" .formatMessage({="" id:="" 'spider.hierarchy_node.platform_customization.parameters.rewards.customizeCardVisualLUploadTitle',="" })="" .format(rewardLabel)}="" authorizedFileTypes="{['JPG'," 'JPEG',="" 'PNG']}="" maxSize="{10}" setOpen="{value" ==""> setIsOpenScreenRightDialog(value)}
        onValid={updateFile}
      />
    </rightdialogfileinput></div>
  );
};

export default withStyles(styles)(ConfigurationImage);
