import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { signOutSilent, signUpRedirect } from '@src/auth';
import { getQueryParam } from '@src/helpers/UrlHelper';
import { AccountActivationStep, AccountActivationSteps } from './components';
import { getAccountActivationErrorRedirectPath } from './utils';
import { Button } from '../../components';
import {
  verifyAccountActivationKeyClear,
  verifyAccountActivationKeyStart,
} from '../../features/auth/accountActivation/slices';

import LogoImg from '../../assets/img/logo.svg';

const useStyles = makeStyles(() => ({
  welcomeContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
}));

const AccountActivation = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const location = useLocation();
  const { contract } = useParams();
  const classes = useStyles();

  const activationState = useSelector(
    state => state.verifyAccountActivationKey,
  );

  const handleClick = () => {
    const key = getQueryParam(location.search, 'key');
    dispatch(verifyAccountActivationKeyStart({ key, contract }));
  };

  useEffect(() => {
    // Assure un état d'authentification propre avant l'activation du compte
    signOutSilent(dispatch);
  }, [dispatch]);

  useEffect(() => {
    const key = getQueryParam(location.search, 'key');

    if (!key) {
      history.push('/');
      return;
    }

    if (activationState.success) {
      const encodedKey = encodeURIComponent(key);
      const redirectUri = `${window.location.origin}/callback?mode=register&node=${contract}&key=${encodedKey}`;

      dispatch(verifyAccountActivationKeyClear());
      signUpRedirect(redirectUri);
    } else if (activationState.error) {
      const redirectPath = getAccountActivationErrorRedirectPath(
        activationState.error,
        contract,
        key,
      );
      dispatch(verifyAccountActivationKeyClear());
      history.push(redirectPath);
    }
  }, [
    activationState.error,
    activationState.success,
    contract,
    dispatch,
    history,
    location.search,
  ]);

  return (
    <>
      <div className="{'ft-main-top'}">
        <accountactivationsteps activeStep="{AccountActivationStep.Activation}"></accountactivationsteps>
      </div>

      <div className="{'ft-content'}">
        <img src="{LogoImg}" alt="{'Logo'}" height="{'56px'}">

        <div className="{classes.welcomeContainer}">
          <typography variant="h1" component="h1" align="{'center'}">
            {intl.formatMessage({ id: 'spider.auth.account_activation.title' })}
          </typography>

          <typography align="{'center'}">
            {intl.formatMessage({
              id: 'spider.auth.account_activation.message',
            })}
          </typography>
        </div>

        <button color="{'primary'}" variant="{'contained'}" size="{'large'}" onClick="{handleClick}" load="{activationState.loading}">
          {intl.formatMessage({ id: 'spider.auth.account_activation.submit' })}
        </button>
      </div>
    </>
  );
};

export default AccountActivation;
