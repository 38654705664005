import account from './endpoints/account';
import auth from './endpoints/auth';
import badgeLevels from './endpoints/badgeLevels';
import badges from './endpoints/badges';
import badgeIcons from './endpoints/badgeIcons';
import categories from './endpoints/categories';
import categoryIcons from './endpoints/categoryIcons';
import challengeAwardTypes from './endpoints/challengeAwardTypes';
import challengeRewardTypes from './endpoints/challengeRewardTypes';
import challengeImages from './endpoints/challengeImages';
import challenges from './endpoints/challenges';
import challengeTypes from './endpoints/challengeTypes';
import coachingItems from './endpoints/coachingItems';
import collaboratorBadgeLevels from './endpoints/collaboratorBadgeLevels';
import collaboratorBadgeSummary from './endpoints/collaboratorBadgeSummary';
import collaboratorChallenges from './endpoints/collaboratorChallenges';
import collaboratorChallengeSummaries from './endpoints/collaboratorChallengeSummaries';
import collaboratorData from './endpoints/collaboratorData';
import collaboratorInput from './endpoints/collaboratorInput';
import collaboratorGoals from './endpoints/collaboratorGoals';
import collaboratorGoalSummaries from './endpoints/collaboratorGoalSummaries';
import collaboratorRewardOrderItems from './endpoints/collaboratorRewardOrderItems';
import collaboratorRewardOrders from './endpoints/collaboratorRewardOrders';
import collaboratorRewardOrderSummaries from './endpoints/collaboratorRewardOrderSummaries';
import collaborators from './endpoints/collaborators';
import configs from './endpoints/configs';
import goalDefinitionLevels from './endpoints/goalDefinitionLevels';
import goalDefinitionLevelsByTeam from './endpoints/goalDefinitionLevelsByTeam';
import goalDefinitionLevelsByCollaborator from './endpoints/goalDefinitionLevelsByCollaborator';
import goalDefinitions from './endpoints/goalDefinitions';
import goalDefinitionRepartitions from './endpoints/goalDefinitionRepartitions';
import goalDefinitionPointRepartitions from './endpoints/goalDefinitionPointRepartitions';
import goalDefinitionPointRepartitionModes from './endpoints/goalDefinitionPointRepartitionModes';
import goalPoints from './endpoints/goalPoints';
import goals from './endpoints/goals';
import goalTypes from './endpoints/goalTypes';
import importLogsOld from './endpoints/importLogs';
import importUsersLogs from './endpoints/importUsersLogs';
import importGoalsLogs from './endpoints/importGoalsLogs';
import inAppNotifications from './endpoints/inAppNotifications';
import kpis from './endpoints/kpis';
import levelIcons from './endpoints/levelIcons';
import levels from './endpoints/levels';
import mails from './endpoints/mails';
import managers from './endpoints/managers';
import menuNotifications from './endpoints/menuNotifications';
import metabase from './endpoints/metabase';
import notifications from './endpoints/notifications';
import hierarchyNodes from './endpoints/hierarchyNodes';
import hierarchyNodeUsers from './endpoints/hierarchyNodeUsers';
import openGraph from './endpoints/openGraph';
import participants from './endpoints/participants';
import pointsTransactions from './endpoints/pointsTransactions';
import posts from './endpoints/posts';
import postComments from './endpoints/postComments';
import partners from './endpoints/partners';
import periodicities from './endpoints/periodicities';
import periods from './endpoints/periods';
import rewardCategories from './endpoints/rewardCategories';
import rewardCategoryIcons from './endpoints/rewardCategoryIcons';
import rewardImages from './endpoints/rewardImages';
import rewards_old from '@async-calls/rewards';
import rewardTypes from './endpoints/rewardTypes';
import roles from './endpoints/roles';
import superManagers from './endpoints/superManagers';
import teamChallenges from './endpoints/teamChallenges';
import teamChallengeSummaries from './endpoints/teamChallengeSummaries';
import teamCollaboratorChallenges from './endpoints/teamCollaboratorChallenges';
import teamCollaboratorChallengeSummaries from './endpoints/teamCollaboratorChallengeSummaries';
import teamCollaboratorGoals from './endpoints/teamCollaboratorGoals';
import teamCollaboratorGoalSummaries from './endpoints/teamCollaboratorGoalSummaries';
import teamGroupCollaboratorChallengeSummaries from './endpoints/teamGroupCollaboratorChallengeSummaries';
import teamGroupChallengeSummaries from './endpoints/teamGroupChallengeSummaries';
import teamGroupBasedChallengeSummaries from './endpoints/teamGroupBasedChallengeSummaries';
import teamGroupBasedChallenges from './endpoints/teamGroupBasedChallenges';
import teamGoals from './endpoints/teamGoals';
import teamGoalSummaries from './endpoints/teamGoalSummaries';
import teamRewardOrderItems from './endpoints/teamRewardOrderItems';
import teamRewardOrders from './endpoints/teamRewardOrders';
import teamRewardOrderSummaries from './endpoints/teamRewardOrderSummaries';
import teams from './endpoints/teams';
import teamGroups from './endpoints/teamGroups';
import tokens from './endpoints/tokens';
import toucanToken from './endpoints/toucanToken';
import userGoals from './endpoints/userGoals';
import users from './endpoints/users';
import userIdentifiers from './endpoints/userIdentifiers';
import units from './endpoints/units';
import weekOverlaps from './endpoints/weekOverlaps';
import contractDetails from './endpoints/contractDetails';
import systemImagesOld from '@async-calls/systemImages';
import rewardAllocationsOld from './endpoints/rewardAllocations';

const api = {
  account,
  auth,
  badgeLevels,
  badges,
  badgeIcons,
  categories,
  categoryIcons,
  challengeAwardTypes,
  challengeRewardTypes,
  challengeImages,
  challenges,
  challengeTypes,
  coachingItems,
  collaboratorBadgeLevels,
  collaboratorBadgeSummary,
  collaboratorChallenges,
  collaboratorChallengeSummaries,
  collaboratorData,
  collaboratorInput,
  collaboratorGoals,
  collaboratorGoalSummaries,
  collaboratorRewardOrderItems,
  collaboratorRewardOrders,
  collaboratorRewardOrderSummaries,
  collaborators,
  configs,
  contractDetails,
  goalDefinitionLevels,
  goalDefinitionLevelsByCollaborator,
  goalDefinitionLevelsByTeam,
  goalDefinitions,
  goalDefinitionRepartitions,
  goalDefinitionPointRepartitions,
  goalDefinitionPointRepartitionModes,
  goalPoints,
  goals,
  goalTypes,
  importLogs: importLogsOld,
  importUsersLogs,
  importGoalsLogs,
  inAppNotifications,
  menuNotifications,
  kpis,
  levelIcons,
  levels,
  mails,
  managers,
  metabase,
  hierarchyNodes,
  hierarchyNodeUsers,
  notifications,
  openGraph,
  pointsTransactions,
  posts,
  postComments,
  participants,
  partners,
  periodicities,
  periods,
  rewardCategories,
  rewardCategoryIcons,
  rewardImages,
  rewards: rewards_old,
  rewardAllocations: rewardAllocationsOld,
  rewardTypes,
  roles,
  systemImages: systemImagesOld,
  superManagers,
  teamChallenges,
  teamChallengeSummaries,
  teamCollaboratorChallenges,
  teamCollaboratorChallengeSummaries,
  teamCollaboratorGoals,
  teamCollaboratorGoalSummaries,
  teamGroupCollaboratorChallengeSummaries,
  teamGroupChallengeSummaries,
  teamGroupBasedChallengeSummaries,
  teamGroupBasedChallenges,
  teamGoals,
  teamGoalSummaries,
  teamRewardOrderItems,
  teamRewardOrders,
  teamRewardOrderSummaries,
  teams,
  teamGroups,
  tokens,
  toucanToken,
  userGoals,
  users,
  userIdentifiers,
  units,
  weekOverlaps,
};

export default api;
