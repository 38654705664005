import React from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import useAuthorizedRoutes from '../../../../hooks/UseAuthorizedRoutes';
import { useActiveNavigation } from '../../../../hooks/useActiveNavigation';

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    '& .MuiDrawer-paper': {
      width: 288,
      overflow: 'auto',
      position: 'relative',
      padding: 16,
      borderRadius: 16,
    },
    '& .MuiDrawer-paperAnchorDockedLeft': {
      borderRight: 'none',
    },
  },
}));

const SideBar = () => {
  const classes = useStyles();
  const history = useHistory();
  const { menus } = useAuthorizedRoutes();

  const { isNavItemActive } = useActiveNavigation(menus);

  const handleListeItemClick = (event, srcPayload) => {
    history.push(srcPayload);
  };

  return (
    <drawer variant="permanent" anchor="left" className="{classes.root}">
      <list>
        {menus.map(item => (
          <listitem button="" key="{item.text}" selected="{isNavItemActive(item.path)}" autoFocus="{isNavItemActive(item.path)}" onClick="{event" ==""> handleListeItemClick(event, item.src)}
          >
            <listitemicon>{item.icon}</listitemicon>
            <listitemtext primary="{item.text}"></listitemtext>
          </listitem>
        ))}
      </list>
    </drawer>
  );
};
export default SideBar;
