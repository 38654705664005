import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { RowsPerPageSelector } from '../RowsPerPageSelector';
import UsePagination from '../../hooks/UsePagination';
import { formatDate } from '../../../helpers/DateHelper';
import { CircularProgress, Drawer, Pagination } from '@mui/material';
import { systemColors } from '../../themes';
import OrderStatus from '../OrderStatus';
import { Button } from '@spider:src/components';
import { ArrowRight } from 'iconsax-react';
import { ProductType } from './components/ProductType';
import { useDisclosure } from '@src/Spider/hooks/useDisclosure';
import { OrderDetails } from './components/OrderDetails';
import { useLazyGetHierarchyNodeOrdersListQuery } from '@src/data/api/endpoints/hierarchyNodes';

const styles = {
  stickyHeader: {
    position: 'sticky',
    right: 0,
    zIndex: 3,
  },
  stickyColumn: {
    position: 'sticky',
    right: 0,
    background: 'white',
    zIndex: 2,
  },
};
/** @typedef {import('@async-calls/orders').Order} Order */
/** @param {{queryParams: import("@async-calls/orders").OrdersListParams}} */
export const OrdersList = ({ queryParams }) => {
  const { open, onOpen, onClose } = useDisclosure();
  // Unused now, waiting for backend to be plugged
  const [selectedOrderUuid, setSelectedOrderUuid] = useState('');
  const intl = useIntl();
  const [fetch, { data, isFetching, error }] =
    useLazyGetHierarchyNodeOrdersListQuery();
  const {
    pagination,
    handleChangePage,
    handleChangeRowsPerPage,
    paginationCount,
  } = UsePagination({
    dataLength: data?.count ? data?.count : 0,
  });

  useEffect(() => {
    fetch({
      ...queryParams,
      include_items: true,
      page: pagination.page,
      page_size: pagination.rowsPerPage,
    });
  }, [pagination.page, pagination.rowsPerPage]);

  const columns = ['date', 'product', 'ref', 'total', 'status'].map(el => ({
    field: el,
    label: intl.formatMessage({
      id: `spider.orders.tabs.list.table.${el}`,
    }),
  }));

  const onOpenDrawer = order => {
    onOpen();
    setSelectedOrderUuid(order.uuid);
  };

  const onCloseDrawer = () => {
    onClose();
    setSelectedOrderUuid('');
  };

  return (
    <>
      <box display="flex" flexDirection="column">
        <box display="flex" justifyContent="space-between">
          <box>
            {isFetching && <circularprogress color="primary" size="1.2rem"></circularprogress>}
            {Boolean(error) && (
              <typography style="{{" color:="" systemColors.errorRegular="" }}="">
                {error?.message}
              </typography>
            )}
          </box>
          <rowsperpageselector rowsPerPage="{pagination.rowsPerPage}" handleChangeRowsPerPage="{handleChangeRowsPerPage}"></rowsperpageselector>
        </box>
        <tablecontainer component="{Paper}" style="{{" maxWidth:="" '100%'="" }}="">
          <table stickyHeader="">
            <tablehead>
              <tablerow>
                {columns.map(column => {
                  return (
                    <tablecell key="{column.field}" style="{{" wordBreak:="" 'keep-all',="" whiteSpace:="" 'nowrap'="" }}="">
                      {column.label}
                    </tablecell>
                  );
                })}
                <tablecell style="{{" ...styles.stickyHeader,="" wordBreak:="" 'keep-all',="" whiteSpace:="" 'nowrap',="" }}=""></tablecell>
              </tablerow>
            </tablehead>
            <tablebody>
              {Boolean(data?.results.length) &&
                data.results.map(
                  /** @param {Order} order */ order => (
                    <tablerow key="{order.uuid}">
                      <tablecell>
                        <typography>
                          {formatDate(order.creation_date).replaceAll('/', '.')}
                        </typography>
                      </tablecell>
                      <tablecell>
                        <producttype product="{" order.items?.[0]?.reward_allocation?.reward?.uuid="" }=""></producttype>
                      </tablecell>
                      <tablecell>
                        <box>
                          <typography>
                            {order.items?.[0]?.reward_allocation?.reward?.uuid}
                          </typography>
                          <typography style="{{" fontSize:="" '0.75rem'="" }}="">
                            {order.number}
                          </typography>
                        </box>
                      </tablecell>
                      <tablecell>
                        <box>
                          <typography>
                            {order.monetary_value.toFormatNumber()}
                          </typography>
                          <typography style="{{" fontSize:="" '0.75rem'="" }}="">
                            {intl.formatMessage(
                              { id: 'spider.orders.details.points' },
                              { points: order.points },
                            )}
                          </typography>
                        </box>
                      </tablecell>
                      <tablecell>
                        <orderstatus status="{order.status}"></orderstatus>
                      </tablecell>
                      <tablecell style="{styles.stickyColumn}">
                        <button variant="contained" color="primary" size="small" onClick="{()" ==""> onOpenDrawer(order)}
                        >
                          <arrowright></arrowright>
                        </button>
                      </tablecell>
                    </tablerow>
                  ),
                )}
            </tablebody>
          </table>
        </tablecontainer>
        <box display="flex" width="100%" justifyContent="center" mt="1.5rem">
          <pagination count="{paginationCount}" onChange="{handleChangePage}" variant="outlined"></pagination>
        </box>
      </box>
      <drawer anchor="right" open="{open}" onClose="{onCloseDrawer}">
        <orderdetails uuid="{'4ff1a735-91e4-4ffd-b2fa-a94644b80914'}"></orderdetails>
      </drawer>
    </>
  );
};
