import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetHierarchyNodeUserDetailsQuery } from '@async-calls/hierarchyNodeUsers';
import {
  Box,
  CircularProgress,
  Typography,
  createTheme,
} from '@material-ui/core';
import { TitleContainer } from '@src/Spider/components/TitleContainer';
import { ParticipantUrl } from '@src/Spider/enums/participantUrl';
import { ProfileCard } from './components/ProfileCard';
import { StatusCard } from './components/StatusCard';
import { DetailsTab } from './tabs/Details';
import { PointsList } from '@src/Spider/components/PointsList/PointsList';
import { OwnerEnum } from '@src/Spider/enums/ownerType';
import { OrdersList } from '@src/Spider/components/OrdersList/OrdersList';
import { TabRoutes } from '@src/Spider/components/TabRoutes';

const Users = () => {
  /** @type {{contract: string, user_id: string}} */
  const { contract, user_id } = useParams();
  const { data, isSuccess, isLoading } = useGetHierarchyNodeUserDetailsQuery({
    user_id,
    include_address: true,
    include_cku: true,
    include_manager: true,
    include_point_balance: true,
  });

  const tabPaths = Object.values(ParticipantUrl);

  if (isLoading)
    return (
      <box sx="{{" display:="" 'flex',="" width:="" '100%',="" height:="" '30vh',="" alignItems:="" 'center',="" justifyContent:="" }}="">
        <circularprogress></circularprogress>
      </box>
    );

  const routes = [
    {
      path: ParticipantUrl.DETAILS,
      component: <detailstab user="{data}" isLoading="{isLoading}"></detailstab>,
    },
    {
      path: ParticipantUrl.POINTS,
      component: (
        <pointslist queryParams="{{" id:="" contract,="" owner_types:="" [OwnerEnum.HIERARCHY_NODE_USER],="" hierarchy_node_user:="" user_id,="" }}=""></pointslist>
      ),
    },
    {
      path: ParticipantUrl.ORDERS,
      component: (
        <orderslist queryParams="{{" id:="" contract,="" owner_types:="" [OwnerEnum.HIERARCHY_NODE_USER],="" hierarchy_node_user:="" user_id,="" }}=""></orderslist>
      ),
    },
  ];

  return (
    <box>
      <titlecontainer>
        <typography variant="h1" component="h1" className="underline-left">
          {data?.firstname} {data?.lastname}
        </typography>
      </titlecontainer>
      <box sx="{{" backgroundColor:="" 'white',="" borderRadius:="" '1.5rem',="" marginTop:="" '2rem',="" }}="">
        <box sx="{{" display:="" 'flex',="" padding:="" '1rem',="" gap:="" [createTheme().breakpoints.down('md')]:="" {="" flexDirection:="" 'column'="" },="" }}="">
          {isSuccess && (
            <>
              <profilecard user="{data}"></profilecard>
              <statuscard user="{data}"></statuscard>
            </>
          )}
        </box>
        <tabroutes basePath="{`/nodes/${contract}/users/${user_id}/infos`}" tabPaths="{tabPaths}" tabNamespace="spider.hierarchy_node_user_pages.tabs" routes="{routes}" defaultTab="{ParticipantUrl.DETAILS}"></tabroutes>
      </box>
    </box>
  );
};

export default Users;
