import React, { useEffect } from 'react';
import { Box, Typography, IconButton } from '@material-ui/core';
import { withStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import { Close as CloseIcon } from '@mui/icons-material';
import { Button } from '@spider:src/components';
import { formatDate } from '@src/helpers/DateHelper';
import { useLazyGetOrderDetailsQuery } from '@src/data/api/endpoints/orders';
import { CircularProgress } from '@mui/material';
import { systemColors } from '@src/Spider';
import { Link } from 'react-router-dom';
import OrderStatus from '../../OrderStatus';
import { InfoCircle, Truck } from 'iconsax-react';
import { GeoLocation } from '../../icons/GeoLocation';

const styles = {
  loading: {
    width: '100%',
    minWidth: '10rem',
    height: '10rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    width: '100%',
    padding: '1.5rem',
    backgroundColor: 'white',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '2rem',
  },
  headerTitle: {
    fontWeight: 'bold',
  },
  cancelButton: {
    marginBottom: '2rem',
    width: '100%',
    color: `${systemColors.errorRegular} !important`,
    borderColor: `${systemColors.errorRegular} !important`,
    border: '2px solid !important',
    borderRadius: '0.5rem !important',
  },
  infoBox: {
    backgroundColor: '#EFF6FF',
    padding: '1.5rem',
    borderRadius: '0.5rem',
    marginBottom: '1.5rem',
  },
  infoSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '1rem',
    marginBottom: '1rem',
  },
  sectionIcon: {
    marginTop: '0.25rem',
  },
  sectionTitle: {
    fontWeight: '700',
    fontSize: '1.5rem',
    marginBottom: '1rem',
  },
  fieldWrapper: {
    display: 'flex',
    gap: '0.5rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  itemValue: {
    fontWeight: '700!important',
    fontSize: '1rem!important',
  },
  addressName: {
    fontWeight: 500,
    marginBottom: '0.5rem',
  },
  addressLine: {
    marginBottom: '0.25rem',
  },
  trackingButton: {
    padding: 0,
    minWidth: 0,
  },
  titleSection: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: '1rem',
    gap: '0.5rem',
  },
  iconTitle: {
    display: 'flex',
    gap: '0.5rem',
  },
};

/**
 * @typedef {import('@src/mock/datasets/ordersList').OrderItem} OrderItem
 * @param {{
 *   classes: any,
 *   uuid: string,
 *   onClose: () => void
 * }} props
 */
const Component = ({ classes, uuid, onClose }) => {
  const intl = useIntl();
  const [fetch, { data, isFetching }] = useLazyGetOrderDetailsQuery(uuid);

  useEffect(() => {
    if (uuid) {
      fetch({ id: uuid });
    }
  }, [uuid, fetch]);

  if (!uuid) return null;

  if (isFetching || !data)
    return (
      <box className="{classes.loading}">
        <circularprogress></circularprogress>
      </box>
    );

  return (
    <box className="{classes.container}">
      <box className="{classes.header}">
        <iconbutton onClick="{onClose}" size="small">
          <closeicon></closeicon>
        </iconbutton>
      </box>
      <box className="{classes.titleSection}">
        <typography variant="h6" className="{classes.headerTitle}">
          {intl.formatMessage({ id: 'spider.orders.details.title' })}
        </typography>
        <button variant="outlined" className="{classes.cancelButton}" onClick="{()" ==""> {
            console.log('Cancel order:', data.uuid);
          }}
        >
          {intl.formatMessage({ id: 'spider.orders.details.cancelOrder' })}
        </button>
      </box>
      <box className="{classes.infoBox}">
        <box className="{classes.infoSection}">
          <box className="{classes.iconTitle}">
            <infocircle></infocircle>
            <typography variant="h3">
              {intl.formatMessage({
                id: 'spider.orders.details.generalInfo',
              })}
            </typography>
          </box>
          <box className="{classes.fieldWrapper}">
            <typography>
              {data.items?.[0]?.reward_allocation?.reward?.name}
            </typography>
            <orderstatus status="{data.status}"></orderstatus>
          </box>
          <box>
            <typography className="{classes.itemValue}">
              {data.items[0]?.reward_allocation?.reward?.uuid}
            </typography>
            <typography variant="body2">
              {intl.formatMessage({ id: 'spider.orders.details.orderRef' })}
            </typography>
          </box>
          <box>
            <typography className="{classes.itemValue}">{data.number}</typography>
            <typography variant="body2">
              {intl.formatMessage({
                id: 'spider.orders.details.orderNumber',
              })}
            </typography>
          </box>
          <box>
            <typography className="{classes.itemValue}">
              {`${data.points} points (${data.monetary_value}€)`}
            </typography>
            <typography variant="body2">
              {intl.formatMessage({ id: 'spider.orders.details.amount' })}
            </typography>
          </box>
          <box>
            <typography className="{classes.itemValue}">
              {formatDate(data.creation_date)}
            </typography>
            <typography variant="body2">
              {intl.formatMessage({ id: 'spider.orders.details.orderDate' })}
            </typography>
          </box>
        </box>
      </box>
      <box className="{classes.infoBox}">
        <box className="{classes.infoSection}">
          <box className="{classes.iconTitle}">
            <geolocation></geolocation>
            <typography variant="h3">
              {intl.formatMessage({ id: 'spider.orders.details.deliveryInfo' })}
            </typography>
          </box>
          <typography className="{classes.addressName}">MOCKED</typography>
          <typography className="{classes.addressLine}">MOCKED</typography>
          <typography>MOCKED</typography>
        </box>
      </box>
      <box className="{classes.infoBox}">
        <box className="{classes.infoSection}">
          <box className="{classes.iconTitle}">
            <truck></truck>
            <typography variant="h3">
              {intl.formatMessage({
                id: 'spider.orders.details.deliveryTracking',
              })}
            </typography>
          </box>
          <typography>
            {intl.formatMessage(
              { id: 'spider.orders.details.here' },
              { ici: <link to="#">ici },
            )}
          </typography>
        </box>
      </box>
    </box>
  );
};

const OrderDetails = withStyles(styles)(Component);
export { OrderDetails };
