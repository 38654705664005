import React, { useMemo } from 'react';
import {
  useLocation,
  useHistory,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { Tab, Tabs, Box } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import { useIntl } from 'react-intl';

export const TabRoutes = ({
  basePath,
  tabPaths,
  tabNamespace,
  routes,
  defaultTab,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  const tabs = useMemo(
    () =>
      tabPaths.map((el, i) => ({
        label: intl.formatMessage({
          id: `${tabNamespace}.${el}`,
        }),
        index: i,
        url: el,
      })),
    [intl, tabPaths, tabNamespace],
  );

  const pathSegments = location.pathname.split('/').filter(Boolean);
  const currentTab = pathSegments.pop();
  const currentTabIndex = tabPaths.indexOf(currentTab);
  const value = String(currentTabIndex !== -1 ? currentTabIndex : 0);

  const handleChange = (_, newValue) => {
    history.push(`${basePath}/${tabPaths[Number(newValue)]}`);
  };

  if (!tabPaths.includes(currentTab)) {
    return <redirect to="{`${basePath}/${defaultTab}`}"></redirect>;
  }

  return (
    <tabcontext value="{value}">
      <box sx="{{" borderBottom:="" 1,="" borderColor:="" 'divider'="" }}="">
        <tabs onChange="{handleChange}" value="{value}">
          {tabs.map((tab, index) => (
            <tab key="{index}" label="{tab.label}" value="{tab.index.toString()}" disableFocusRipple=""></tab>
          ))}
        </tabs>
      </box>
      <switch>
        {routes.map((route, index) => (
          <route key="{index}" path="{`${basePath}/${route.path}`}" render="{()" ==""> (
              <tabpanel value="{tabs[index].index.toString()}">
                {route.component}
              </tabpanel>
            )}
          />
        ))}
        <route path="*" render="{()" ==""> <redirect to="{`${basePath}/${defaultTab}`}"></redirect>}
        />
      </route></route></switch>
    </tabcontext>
  );
};
