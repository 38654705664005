import React, { useState } from 'react';
import {
  DialogContent,
  Drawer,
  Paper,
  Tab,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { withStyles } from '@mui/styles';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { useIntl } from 'react-intl';
import { ImportContent } from './components/ImportContent';
import useParticipantsHelper from '@src/Spider/hooks/Participants/UseParticipantsHelper';
import usePointHelper from '@src/Spider/hooks/Points/UsePointHelper';
import { DialogIndicator } from '@spider:components/DialogIndicator';
import { neutralColors } from '@src/Spider/themes';
import { createTheme } from '@material-ui/core/styles';
import { ImportLogsTypes } from '@src/Spider/enums/importLogsTypes';
import { useParams } from 'react-router-dom';
import { useAuth } from '@src/auth';
import { CloseButtonIcon } from '@spider:components/CloseButtonIcon';
import useSystemFileRefactor from '@src/Spider/hooks/useSystemFileRefactor';
import { useCreateImportLogMutation } from '@async-calls/importLogs';

const styles = {
  dialogTitle: {
    display: 'flex !important',
    flexDirection: 'column !important',
    padding: '1rem !important',
    width: '100%',
  },
  closeTitleDialog: {
    display: 'flex !important',
    width: '100%',
    flexDirection: 'row-reverse !important',
    padding: '1rem !important',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    gap: '1rem',
    overflow: 'auto',
    backgroundColor: neutralColors.neutralBg,
    [createTheme().breakpoints.up('md')]: {
      padding: '0rem 2rem 2rem 2rem !important',
    },
  },
  dialogPaperContent: {
    borderRadius: '1rem',
    marginRight: 'auto',
    marginLeft: 'auto',
    '&.MuiPaper-root': {
      boxShadow: 'none',
    },
    [createTheme().breakpoints.up('md')]: {
      padding: '1rem',
      width: '75%',
    },
  },
  drawer: {
    '& .MuiDrawer-paper': {
      borderTopLeftRadius: '0.75rem',
      borderTopRightRadius: '0.75rem',
      border: 'none',
      height: '90vh',
      [createTheme().breakpoints.up('sm')]: {
        height: '85vh',
        border: '4px solid',
        borderBottom: 'none',
        borderColor: neutralColors.neutral300,
      },
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: neutralColors.neutralBg,
    cursor: 'pointer',
    minHeight: '48px',
    [createTheme().breakpoints.down('sm')]: {
      minHeight: '6px',
    },
  },
};

const ImportDialog = ({
  fixedCacheKey,
  open,
  setOpen,
  defaultTab,
  onFileCreated,
  ...props
}) => {
  const intl = useIntl();
  const { contract } = useParams();
  const { hierarchyNodeUser } = useAuth();

  const isMobile = useMediaQuery(createTheme().breakpoints.down('sm'));

  const { updateSystemFile } = useSystemFileRefactor({
    fixedCacheKey,
    onFileUpdated: additionalProps => {
      onFileCreated(additionalProps);
      onClose();
    },
    updateSystemFileMutation: useCreateImportLogMutation,
  });

  const {
    importRequirements: participantsImportRequirements,
    onDownloadImportPattern: participantOnDownloadImportPattern,
  } = useParticipantsHelper();
  const {
    importRequirements: pointsImportRequirements,
    onDownloadImportPattern: pointsOnDownloadImportPattern,
  } = usePointHelper();

  const POINTS_TAB = {
    label: intl.formatMessage({ id: 'spider.points.title' }),
    index: '1',
  };
  const PARTICIPANTS_TAB = {
    label: intl.formatMessage({ id: 'spider.participants.title' }),
    index: '2',
  };

  const getDefaultTab = () => {
    return defaultTab ? defaultTab : PARTICIPANTS_TAB.index;
  };

  const [tabValue, setTabValue] = useState(getDefaultTab());

  const handleChange = (event, tab) => {
    setTabValue(tab);
  };

  const onParticipantValidate = async files => {
    await onValidate(files, ImportLogsTypes.USERS);
  };

  const onPointValidate = async files => {
    await onValidate(files, ImportLogsTypes.POINTS);
  };

  const onValidate = async (files, type) => {
    const file = new FormData();
    file.append('type', type);
    file.append('file', files[0]);
    file.append('hierarchy_node_user', hierarchyNodeUser.uuid);
    await updateSystemFile(file, { hierarchyNodeId: contract, type });
  };

  const onClose = () => {
    setOpen(false);
    setTabValue(getDefaultTab());
  };

  return (
    <drawer anchor="bottom" open="{open}" onClose="{onClose}" className="{props.classes.drawer}">
      <dialogcontent className="{props.classes.dialogContent}">
        <div className="{props.classes.header}" onClick="{onClose}">
          <dialogindicator></dialogindicator>
        </div>
        {!isMobile && (
          <div className="{props.classes.closeTitleDialog}">
            <closebuttonicon onClick="{onClose}"></closebuttonicon>
          </div>
        )}
        <div className="{props.classes.dialogTitle}">
          <div>
            <typography variant="{'h1'}" component="{'h1'}" className="{'title" underline-center'}="">
              {intl.formatMessage({ id: 'spider.importDialog.title' })}
            </typography>
          </div>
        </div>
        <paper className="{props.classes.dialogPaperContent}">
          <tabcontext value="{tabValue}">
            <tablist onChange="{handleChange}" TabIndicatorProps="{{" children:="" <div=""></tablist> }}
              className={'switch'}
            >
              <tab label="{POINTS_TAB.label}" value="{POINTS_TAB.index}" disableFocusRipple=""></tab>
              <tab label="{PARTICIPANTS_TAB.label}" value="{PARTICIPANTS_TAB.index}" disableFocusRipple=""></tab>
            
            <tabpanel value="{POINTS_TAB.index}">
              <importcontent key="{'imports'}" title="{intl.formatMessage({" id:="" 'spider.points.helpImport.header.title',="" })}="" subtitle="{intl.formatMessage({" 'spider.points.helpImport.header.subtitle',="" downloadPattern="{pointsOnDownloadImportPattern}" requirementObject="{pointsImportRequirements}" validateImport="{onPointValidate}"></importcontent>
            </tabpanel>
            <tabpanel value="{PARTICIPANTS_TAB.index}">
              <importcontent key="{'participants'}" title="{intl.formatMessage({" id:="" 'spider.participants.helpImport.header.title',="" })}="" subtitle="{intl.formatMessage({" 'spider.participants.helpImport.header.subtitle',="" downloadPattern="{participantOnDownloadImportPattern}" requirementObject="{participantsImportRequirements}" validateImport="{onParticipantValidate}"></importcontent>
            </tabpanel>
          </tabcontext>
        </paper>
      </dialogcontent>
    </drawer>
  );
};

export default withStyles(styles)(ImportDialog);
