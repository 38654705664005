import { Chip } from '@material-ui/core';
import { CloseCircle, TickCircle } from 'iconsax-react';
import React from 'react';
import { systemColors } from '..';
import { useIntl } from 'react-intl';

/** @param {{hasUserAccount: boolean}} props */
export const AccountStatus = ({ hasUserAccount }) => {
  const { formatMessage } = useIntl();

  const params = {
    label: formatMessage({
      id: `spider.hierarchy_node_user_status.account.${hasUserAccount ? 'attached' : 'notAttached'}`,
    }),
    style: hasUserAccount ? 'success width-fit' : 'partial width-fit',
    icon: hasUserAccount ? (
      <tickcircle color="{systemColors.successRegular}"></tickcircle>
    ) : (
      <closecircle color="{systemColors.warningRegular}"></closecircle>
    ),
  };

  return (
    <chip label="{params.label}" className="{params.style}" icon="{params.icon}"></chip>
  );
};
