export const OrderStatuses = Object.freeze({
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
  PENDING: 'PENDING',
});

/**
 *  @typedef { OrderStatuses[keyof OrderStatuses] } OrderStatusesType - Statuses for an order , should use the enum in `OrderStatuses.jsx`
 * @exports { OrderStatusesType };
 */
