import React from 'react';
import { Box, createTheme } from '@material-ui/core';
import { neutralColors } from '@src/Spider';
import { Typography } from '@mui/material';

/**
 * @typedef {Object} Props
 * @property {string} title
 * @property {import('react').ReactNode} [icon]
 * @property {import('react').ReactNode} [action]
 */

/**
 * @typedef {import('react').PropsWithChildren<props>} Cardprops
 */

/**
 * @param {Cardprops} props
 * @returns {JSX.Element}
 */
const Card = ({ children, action, title, icon }) => {
  return (
    <box sx="{{" backgroundColor:="" neutralColors.neutralColdWhite,="" padding:="" '1rem',="" borderRadius:="" display:="" 'flex',="" flexDirection:="" 'column',="" gap:="" '1.5rem',="" }}="">
      <box sx="{{" display:="" 'flex',="" justifyContent:="" 'space-between'="" }}="">
        <box sx="{{" display:="" 'flex',="" gap:="" '0.5rem',="" alignItems:="" 'center'="" }}="">
          {icon}
          <typography fontFamily="inherit" fontWeight="700" fontSize="1.25rem">
            {title}
          </typography>
        </box>
        {action}
      </box>
      <box sx="{{" display:="" 'grid',="" gridTemplateColumns:="" 'repeat(3,="" 1fr)',="" [createTheme().breakpoints.down('md')]:="" {="" 'repeat(2,="" },="" [createTheme().breakpoints.down('sm')]:="" 'repeat(1,="" gap:="" '1rem',="" gridAutoRows:="" 'auto',="" }}="">
        {children}
      </box>
    </box>
  );
};

/** @param {{label: string, value: string}} props */
const Item = ({ label, value }) => (
  <box>
    <typography fontFamily="inherit" fontWeight="700">
      {value}
    </typography>
    <typography fontFamily="inherit" fontSize="0.75rem">
      {label}
    </typography>
  </box>
);

export const Info = {
  Card,
  Item,
};
</props>