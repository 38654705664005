import React from 'react';
import { Dialog } from '@mui/material';
import { Box, Typography, withStyles } from '@material-ui/core';
import { Button } from '@src/Spider/components';
import { CloseButtonIcon } from '@src/Spider/components/CloseButtonIcon';
import { useIntl } from 'react-intl';

const styles = {
  text: {
    textAlign: 'center',
  },
  dialog: { borderRadius: '1.5rem', gap: '0rem' },
};

/**
 * @typedef {Object} ComponentProps
 * @property {() => void} handleClose - Function to close the modal
 * @property {boolean} open - Controls modal visibility
 * @property {string} title - intl id title
 * @property {string} description - intl id description text
 * @property {string} confirmButtonText - intl id text for the confirm button
 * @property {string} cancelButtonText - intl id text for the cancel button
 * @property {() => void | Promise<void>} onConfirm - Function to execute on confirmation
 * @property {boolean} [isLoading=false] - Loading state for the confirm button
 */

/**
 * Base component for the action modal before styling
 * @param {ComponentProps} props
 * @returns {JSX.Element}
 */
const Component = ({
  handleClose,
  open,
  title,
  description,
  confirmButtonText,
  cancelButtonText,
  onConfirm,
  isLoading = false,
  classes,
}) => {
  const { formatMessage } = useIntl();

  return (
    <dialog onClose="{handleClose}" open="{open}" maxWidth="xs" className="{classes.dialog}">
      <box>
        <box sx="{{" display:="" 'flex',="" width:="" '100%',="" justifyContent:="" 'end'="" }}="">
          <closebuttonicon onClick="{handleClose}"></closebuttonicon>
        </box>
        <box sx="{{" display:="" 'flex',="" flexDirection:="" 'column',="" gap:="" '1.5rem',="" alignItems:="" 'center',="" }}="">
          <typography variant="h1" component="h1" className="underline-center">
            {formatMessage({ id: title })}
          </typography>
          <typography className="{classes.text}">
            {formatMessage({ id: description })}
          </typography>
          <box sx="{{" display:="" 'flex',="" gap:="" '1rem'="" }}="">
            <button variant="outlined" color="primary" size="small" onClick="{handleClose}">
              {formatMessage({ id: cancelButtonText })}
            </button>
            <button variant="contained" color="primary" size="small" onClick="{onConfirm}" load="{isLoading}" disabled="{isLoading}">
              {formatMessage({ id: confirmButtonText })}
            </button>
          </box>
        </box>
      </box>
    </dialog>
  );
};

const ActionModal = withStyles(styles)(Component);

export { ActionModal };
</void>