import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { withStyles } from '@mui/styles';
import { createTheme } from '@material-ui/core/styles';
import { Loader } from '@src/components';
import { setCommandId } from '@spider:src/features/convertPoint/stepperData/slices';
import { RewardCard } from './components/RewardCard';
import { PointCommandIds } from '@spider:src/enums';
import { useGetRewardsListQuery } from '@async-calls/rewards';

const styles = {
  root: {
    display: 'flex',
    [createTheme().breakpoints.up('md')]: {},
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '1.5rem',
    paddingRight: '0.5rem',

  },
  cards: {
    display: 'grid',
    gap: '1.5rem',
    gridTemplateColumns: 'repeat(1, 1fr)',
    [createTheme().breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [createTheme().breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
  },
  card: {
    display: 'flex',
    position: 'relative',
  },
};

const UserHome = ({ ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { contract } = useParams();

  const { data, isFetching, isSuccess } = useGetRewardsListQuery({
    hierarchyNodeId: contract,
    allocatedOnly: true,
    distributor: 'Edenred',
  });

  const openConvertPointView = reward => {
    dispatch(setCommandId(reward));
    sessionStorage.setItem('reward', JSON.stringify(reward));
    history.push(`/nodes/${contract}/conversion-points`);
  };

  const specialReward = useMemo(() => {
    if (!data?.results) return null;
    return (
      data.results.find(reward => reward.uuid === PointCommandIds.KSC) ?? null
    );
  }, [data]);

  return (
    <div className="{props.classes.root}">
      {isSuccess && specialReward && (
        <rewardcard key="{specialReward.uuid}" reward="{specialReward}" convert="{openConvertPointView}"></rewardcard>
      )}
      {isFetching && <loader centered=""></loader>}
      {isSuccess && (
        <div className="{props.classes.cards}" style="{{" width:="" '100%'="" }}="">
          {data?.results.map(reward => {
            return reward.uuid !== PointCommandIds.KSC ? (
              <rewardcard key="{reward.uuid}" reward="{reward}" convert="{openConvertPointView}"></rewardcard>
            ) : (
              <react.fragment key="{reward.uuid}"></react.fragment>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(UserHome);
