import React from 'react';
import { withStyles } from '@mui/styles';
import { ReactComponent as Ellipse } from '../../../../assets/img/ellipse.svg';
import { ReactComponent as InfoCircle } from '../../../../assets/img/homeParticipantsConvert/info-circle.svg';
import { Button } from '../../../index';
import { Paper, Tooltip, Typography } from '@material-ui/core';
import { useAuth } from '../../../../../auth';
import { useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { ArrowRight } from 'iconsax-react';
import { useSelector } from 'react-redux';
import { neutralColors } from '../../../../themes';

const styles = {
  root: {
    '& .MuiPaper-root': {
      display: 'flex',
      flexDirection: 'row',
      gap: '0.5rem',
      boxShadow: 'none !important',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: 288,
      overflow: 'clip',
      position: 'relative',
      padding: '0.5rem 1rem',
      borderRadius: '1rem',
    },
  },
  points: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: '2',
    paddingLeft: '1.5rem',
  },
  pointsDetails: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '0.5rem',
  },
  ellipse: {
    zIndex: '1',
    position: 'absolute',
    width: '9rem',
    height: '4rem',
  },
};

const PointConverterShortcut = ({ ...props }) => {
  const history = useHistory();
  const intl = useIntl();
  const { hierarchyNodeUser } = useAuth();
  const { contract } = useParams();

  const mainState = useSelector(state => state.mainKey);

  const expirationDate = hierarchyNodeUser?.point_balance?.next_expiration
    ?.usability_end
    ? new Date(
        hierarchyNodeUser.point_balance.next_expiration.usability_end * 1000,
      ).toLocaleDateString('fr-FR')
    : null;

  const expiringPoints =
    hierarchyNodeUser?.point_balance?.next_expiration?.available || 0;

  const goToHome = () => {
    history.push(`/nodes/${contract}/home`);
  };

  return (
    <div className="{props.classes.root}">
      <ellipse className="{props.classes.ellipse}" fill="{mainState.config.mainColor.value}"></ellipse>
      <paper>
        <div className="{props.classes.points}">
          <typography variant="{'h2'}" component="{'h2'}" style="{{" fontWeight:="" 'bold',="" color:="" neutralColors.neutralWhite="" }}="">
            {hierarchyNodeUser?.point_balance?.available?.toFormatNumber(false)}
          </typography>
          <div className="{props.classes.pointsDetails}">
            <typography variant="{'body2'}" style="{{" color:="" neutralColors.neutralWhite="" }}="">
              {intl.formatMessage({ id: 'spider.convertShortcut.points' })}
            </typography>
            <tooltip title="{intl.formatMessage(" {="" id:="" 'spider.convertShortcut.tooltips_information',="" },="" points:="" expiringPoints,="" date:="" expirationDate,="" )}="" placement="top" arrow="">
              <infocircle fill="{neutralColors.neutralWhite}"></infocircle>
            </tooltip>
          </div>
        </div>
        <button color="{'primary'}" variant="{'contained'}" size="{'small'}" onClick="{goToHome}" style="{{" padding:="" '0.25rem="" 0.5rem',="" height:="" '2rem'="" }}="">
          {intl.formatMessage({ id: 'spider.convertShortcut.convertAction' })}
          <arrowright size="16"></arrowright>
        </button>
      </paper>
    </div>
  );
};

export default withStyles(styles)(PointConverterShortcut);
