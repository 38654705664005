import { Card, Paper, Typography } from '@material-ui/core';
import { TitleContainer } from '@src/Spider/components/TitleContainer';
import React from 'react';
import { useIntl } from 'react-intl';
import { withStyles } from '@mui/styles';

const styles = {
  pointsBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    paddingRight: '0.5rem',
  },
};

const Help = ({ ...props }) => {
  const intl = useIntl();

  return (
    <div className="{props.classes.pointsBody}">
      <titlecontainer>
        <typography variant="{'h1'}" component="{'h1'}" className="{'underline-left'}">
          {intl.formatMessage({ id: 'spider.sidebar.user.help' })}
        </typography>
      </titlecontainer>
      <paper elevation="{0}"></paper>
    </div>
  );
};

export default withStyles(styles)(Help);
